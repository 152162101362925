import * as React from "react"
import Layout from "../../components/layout"
import '../../style.css'
import Star from "../../components/star";
import Vid from "../../components/video";
import Accordion from 'react-bootstrap/Accordion';

const Blackhole = () => {

    const info={
        video:true,
        title:'Black Hole Video',
        link:'https://www.youtube.com/embed/YjpX9snW2Mw',
        description:'A short 26 second video, which shows an animation of a distant black hole complete with jets and an accretion disk. (No Audio)',
        internalText:'Distant view of supermassive black hole, with jets and an accretion disk. Animation slowly approaches the event horizon of the black hole as material moves out along the jets and spirals in toward the center of the black hole.',
        transcript:['*This video has no audio*']
      }

  return (
    <Layout>
        <div style={{width:'100%'}}>
            <h1>{info.title}</h1>
            <Star />
            <Vid videoLink={info.link}/>
            <Accordion flush>
                <Accordion.Item eventKey={1} key={1}>
                        <Accordion.Header>Transcript</Accordion.Header>
                        <Accordion.Body>
                                {info.transcript ? 
                                    info.transcript.map( p => <p style={{textAlign:'start'}}>{p}</p>)
                                    : 
                                    null
                                }
                        </Accordion.Body>
                </Accordion.Item>
            </Accordion>
            <p style={{textAlign:'start', margin:'1em 0'}}>{info.internalText}</p>
        </div>
    </Layout>
  )
}

export default Blackhole